import { Maybe, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

export const calculateChangeFromTimeSeries = (
  timeSeries: Maybe<number>[],
  granularity?: MhcTimeSeriesGranularityEnum | null
): number | undefined => {
  if (timeSeries.length < 2) {
    return undefined;
  }
  const first = timeSeries[0];
  let last = timeSeries[timeSeries.length - 1];
  if (granularity === "week" && timeSeries.length >= 3) {
    last = timeSeries[timeSeries.length - 3];
  }
  if (first === undefined || last === undefined || first === null || last === null) {
    return undefined;
  }
  if (first === 0) {
    return Infinity;
  }
  return ((last - first) / first) * 100;
};

export const calculateRange = (timestamps: number[]): string | undefined => {
  if (timestamps.length < 2) {
    return undefined;
  }
  const first = timestamps[0];
  const last = timestamps[timestamps.length - 1];
  if (first === undefined || last === undefined) {
    return undefined;
  }
  const startDate = new Date(first * 1000);
  const endDate = new Date(last * 1000);
  return `(${startDate.getFullYear()} - ${endDate.getFullYear()})`;
};
