import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import { MhcResource } from "graphqlApi/types";

import { ExternalLink } from "../../common/components/ExternalLink";

interface LinkItem {
  url: string;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
}

export interface ContentCardLinkListProps {
  links: (LinkItem | MhcResource)[];
}

const ContentCardLinkList: React.FC<ContentCardLinkListProps> = ({ links }) => {
  return (
    <Stack gap={{ xs: 3, md: 2 }}>
      {links.map(({ url, title, description }, i) => {
        return (
          <Box key={i}>
            <Typography>
              {url ? (
                <ExternalLink href={url}>{title}</ExternalLink>
              ) : (
                <Typography fontWeight={600}>{title}</Typography>
              )}
            </Typography>
            {description && <Typography>{description}</Typography>}
          </Box>
        );
      })}
    </Stack>
  );
};

export default ContentCardLinkList;
